<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ $t('routes.billingManagement') }}</h1>
        <div class="d-flex gap-2">
          <el-select v-model="supplierId" size="mini" placement="end" filterable clearable @clear="supplierId = null">
            <el-option
              v-for="supplier in suppliersFilter"
              :key="supplier.id"
              :label="supplier.name"
              :value="supplier.id"
            />
          </el-select>
          <MonthPicker v-model="month" size="mini" />
        </div>
      </div>
      <p class="fw-bold">{{ subtitle }}</p>
    </template>
    <template #dashboard>
      <BillingOverview
        class="col-6 h-100"
        :loading="commonDataLoading"
        :billings="commonDataLoading ? [] : events.billings"
        :missing-events="commonDataLoading ? [] : relevantMissingEvents"
        :supplier="currentSupplier"
        :month="month"
      />
    </template>
    <template #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    </template>
    <BillingTable
      v-if="activeTab === 0"
      ref="billingTable"
      :key="`billing-table-month-${month}`"
      :events-loading="commonDataLoading"
      :documents-loading="documentsLoading"
      :events="commonDataLoading ? {} : events"
      :documents="documentsLoading ? [] : activityDocuments"
      :reconciliation-documents="reconciliationDocuments"
      :reconciliations="reconciliations"
      :month="month"
      :customer="customer"
      :suppliers="suppliers"
      :missing="relevantMissingEvents"
      :products-loading="productsLoading"
      :is-admin="isAdmin"
      :order-differences="currentOrderDifferences"
      :delivery-differences="currentDeliveryDifferences"
      :reconciliation-templates="reconciliationTemplates"
      @close-reconciliation="handleCloseReconciliation"
      @create-reconciliation-template="handleCreateTemplate"
      @update-reconciliation-template="handleUpdateTemplate"
      @open-document="handleOpenDocument"
      @open-activity="activity = $event"
      @open-event-map="routeToSupplier"
      @chat-open="handleChatOpen"
      @chat-close="handleChatClose"
      @row-click="handleBillingTableRowClick"
      @resolve-missing-event="patchMissingEvent({ id: $event, data: { resolved: true } })"
      @validate-balance-alignment="validateBalanceAlignment"
    />
    <UnapprovedTable
      v-if="activeTab === 1"
      :key="`unapproved-table-month-${month}`"
      :events-loading="commonDataLoading"
      :documents-loading="documentsLoading"
      :events="events"
      :documents="activityDocuments"
      :month="month"
      :suppliers="suppliers"
      :missing-events="relevantMissingEvents"
      :is-admin="isAdmin"
      @open-document="handleOpenDocument"
      @open-activity="activity = $event"
      @resolve-missing-event="patchMissingEvent({ missingEventId: $event, resolved: true })"
    />
    <DocumentModal
      v-if="displayedDocument.documentId"
      visible
      :document-id="displayedDocument.documentId"
      @close="displayedDocument = {}"
    >
      <template v-if="displayedDocument.documentsIds && displayedDocument.documentsIds.length" #header>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :page-size="1"
          :total="displayedDocument.documentsIds.length"
          :current-page="displayedDocument.index + 1"
          :pager-count="5"
          @update:currentPage="handleDocumentModalPagination"
        />
      </template>
    </DocumentModal>
    <EventMapModal v-if="activity" :activity="activity" @close="activity = null" />
  </PageLayout>
</template>

<script>
import { DateTime } from 'luxon';
import { uniq, flatten, reject, isNil, groupBy, prop } from 'ramda';
import { computed, onBeforeUnmount, ref, watch, getCurrentInstance } from 'vue';

import { PageLayout, MonthPicker, Tabs } from '@/modules/core';
import { useSuppliersNew } from '@/modules/suppliers';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { useOrderCreate, useEventDifferences, useOrderProductPatch, useOrderPatch } from '@/modules/order';
import { useDeliveryChange } from '@/modules/delivery';
import { DocumentModal } from '@/modules/documentModal';
import { useTenancy, useUser } from '@/modules/auth';
import {
  useReconciliationTemplates,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
  useReconciliations,
  useMissingEvents,
  usePatchMissingEvent,
  useUpdateReconciliation,
  useCloseReconciliation,
} from '@/modules/reconciliation';
import { useCreateOrderFromDocument } from '@/modules/eventMapModal';
import { useChatModal } from '@/modules/chatModal';
import { options } from '@/locale/dateConfig';

import {
  useActivity,
  useReconciliationDocuments,
  useActivityDocuments,
  useProductsNew,
  useBillingPatch,
} from './compositions';
import { BillingOverview, BillingTable, UnapprovedTable } from './components';

export default {
  components: {
    PageLayout,
    BillingOverview,
    MonthPicker,
    Tabs,
    BillingTable,
    UnapprovedTable,
    DocumentModal,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
  },
  setup() {
    const { $router, $message, $i18n } = getCurrentInstance().proxy;
    const { currentTenant: customer } = useTenancy();
    const { isAdmin } = useUser();

    const supplierId = ref($router.currentRoute.query.supplierId || null);
    const fromDate = ref(
      DateTime.fromISO($router.currentRoute.query.month || new DateTime.local().toFormat('yyyy-LL'), {
        zone: 'utc',
      })
    );
    const toDate = computed(() => fromDate.value.endOf('month'));

    const { mutate: closeReconciliation } = useCloseReconciliation();

    const { suppliers, loading: suppliersLoading, refetch: refetchSuppliers } = useSuppliersNew();

    const {
      missingEvents,
      loading: missingEventsLoading,
      refetch: refetchMissingEvents,
    } = useMissingEvents(() =>
      reject(isNil, {
        businessId: customer.value.id,
        supplierId: supplierId.value,
      })
    );

    const { documents: rehandleParentDocuments, loading: rehandleParentDocumentsLoading } = useDocumentsByIds(
      computed(() => ({
        businessId: customer.value.id,
        ids: missingEvents.value
          .filter(({ parentDocumentId }) => parentDocumentId)
          .map(({ parentDocumentId }) => parentDocumentId),
      }))
    );

    const {
      reconciliations,
      loading: reconciliationsLoading,
      refetch: refetchReconciliations,
    } = useReconciliations(
      computed(() => ({
        businessId: customer.value.id,
        supplierId: supplierId.value,
        fromPeriodDate: fromDate.value.toISODate(),
        toPeriodDate: toDate.value.toISODate(),
      })),
      computed(() => ({ enabled: !!customer.value.id }))
    );

    const {
      patchMissingEvent,
      onDone: patchMissingEventOnDone,
      onError: patchMissingEventOnError,
    } = usePatchMissingEvent();

    patchMissingEventOnDone(() =>
      $message.success($i18n.t('billing.billingManagement.messages.missingEventResolvedSuccess'))
    );
    patchMissingEventOnError(() =>
      $message.error($i18n.t('billing.billingManagement.messages.missingEventResolvedError'))
    );

    const activityVariables = computed(() => ({
      businessId: customer.value.id,
      supplierId: supplierId.value,
      fromDate: fromDate.value.toISODate(),
      toDate: toDate.value.toISODate(),
    }));

    const reconciliationVariables = computed(() => ({
      businessId: customer.value.id,
      supplierId: supplierId.value,
      fromDate: fromDate.value.minus({ years: 1 }).minus({ days: 1 }).toISODate(),
      toDate: toDate.value.plus({ months: 1 }).toISODate(),
    }));

    const { eventDifferences, refetch: eventDifferencesRefetch } = useEventDifferences(
      computed(() => ({ customerId: customer.value.id }))
    );

    const { reconciliationTemplates, refetch: reconciliationTemplatesRefetch } = useReconciliationTemplates(
      computed(() => ({
        businessId: customer.value.id,
      }))
    );

    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();

    const currentDeliveryDifferences = computed(() =>
      eventDifferences.value.deliveryDifferences
        .filter(
          (diff) =>
            fromDate.value.toISODate() <= diff.date &&
            diff.date <= toDate.value.toISODate() &&
            (supplierId.value ? supplierId.value === diff.supplierId : true)
        )
        .map((delivery) => ({ ...delivery, imbalances: [], toValidate: true }))
    );

    const currentOrderDifferences = computed(() =>
      eventDifferences.value.orderDifferences
        .filter(
          (diff) =>
            fromDate.value.toISODate() <= diff.date &&
            diff.date <= toDate.value.toISODate() &&
            (supplierId.value ? supplierId.value === diff.supplierId : true)
        )
        .map((order) => ({
          ...order,
          toValidate: true,
          delivery: currentDeliveryDifferences.value.find(
            (delivery) => delivery.eventReferences[0].documentId === order.eventReferences[0].documentId
          ),
        }))
    );

    const {
      events: eventsWithoutProducts,
      loading: eventsLoading,
      refetch: refetchEvents,
    } = useActivity(activityVariables);
    const { activityDocuments, loading: activityDocumentsLoading } = useActivityDocuments(activityVariables);
    const { reconciliationDocuments, loading: reconciliationDocumentsLoading } =
      useReconciliationDocuments(reconciliationVariables);

    const { products, loading: productsLoading } = useProductsNew(
      computed(() => ({
        ids: reject(isNil)(
          uniq(
            flatten(
              eventsWithoutProducts.value.orders?.map((order) => order.differences.map(({ productId }) => productId))
            )
          )
        ),
      }))
    );

    const events = computed(() => ({
      ...eventsWithoutProducts.value,
      orders: eventsWithoutProducts.value.orders.map((order) => ({
        ...order,
        differences: order.differences.map((difference) => {
          const relevantProduct = products.value.find((product) => product.id === difference.productId);
          return reject(isNil)({ ...difference, product: { name: relevantProduct?.name, sku: relevantProduct?.sku } });
        }),
      })),
    }));

    const month = computed({
      set(month) {
        fromDate.value = DateTime.fromJSDate(new Date(month));
      },
      get() {
        return fromDate.value.toFormat('yyyy-LL');
      },
    });

    const { mutate: updateReconciliation } = useUpdateReconciliation();

    const { onDone: onOrderChange } = useOrderProductPatch();
    const { onChange: onDeliveryChange } = useDeliveryChange();
    const { onDone: patchBillingOnDone } = useBillingPatch();
    const { onDone: orderCreateOnDone } = useOrderCreate();
    const { onDone: orderPatchOnDone } = useOrderPatch();
    const { onDone: createOrderFromDocumentOnDone } = useCreateOrderFromDocument();

    const refetchData = () => {
      refetchReconciliations();
      refetchEvents();
      eventDifferencesRefetch();
      refetchMissingEvents();
    };

    const timeoutIds = [];
    const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

    onOrderChange(refetchData);
    onDeliveryChange(refetchData);
    patchBillingOnDone(refetchData);
    patchMissingEventOnDone(refetchData);
    orderCreateOnDone(async () => {
      await sleep(500);
      refetchData();
    });
    orderPatchOnDone(async () => {
      await sleep(500);
      refetchData();
    });
    createOrderFromDocumentOnDone(async () => {
      await sleep(1000);
      refetchEvents();
      eventDifferencesRefetch();
    });

    const { closeChatModal, openChatModal, isChatOpen } = useChatModal();

    watch(month, () => closeChatModal(), { immediate: true });

    watch([supplierId, month], ([newSupplierId, newMonth]) =>
      $router.replace({ query: reject(isNil)({ supplierId: newSupplierId, month: newMonth }) })
    );

    watch(
      [reconciliationDocuments, events],
      async ([newReconciliations, newEvents]) => {
        if (suppliers.value?.length) {
          const suppliersById = groupBy(prop('id'))(suppliers.value);
          const newSupplierInData = [
            ...newReconciliations,
            ...newEvents.billings,
            ...newEvents.orders,
            ...newEvents.deliveries,
          ].some(({ supplierId }) => !suppliersById[supplierId]);

          if (newSupplierInData) await refetchSuppliers();
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    return {
      reconciliations,
      refetchReconciliations,
      closeReconciliation,
      reconciliationsLoading,
      isAdmin,
      customer,
      reconciliationTemplates,
      reconciliationTemplatesRefetch,
      createReconciliationTemplate,
      updateReconciliationTemplate,
      updateReconciliation,
      month,
      missingEventsLoading,
      patchMissingEvent,
      reconciliationDocuments,
      activityDocuments,
      products,
      activityDocumentsLoading,
      productsLoading,
      reconciliationDocumentsLoading,
      rehandleParentDocumentsLoading,
      relevantMissingEvents: computed(() =>
        rehandleParentDocumentsLoading.value
          ? []
          : missingEvents.value
              .filter((missingEvent) => !supplierId.value || missingEvent.supplierId === supplierId.value)
              .map((missingEvent) => {
                const newMissingEvent = { ...missingEvent };

                if (missingEvent.parentDocumentId) {
                  newMissingEvent.parentDocument = rehandleParentDocuments.value?.find(
                    (document) => document.id === missingEvent.parentDocumentId
                  );
                }

                return newMissingEvent;
              })
              .filter((missingEvent) => {
                if (missingEvent.estimatedDate) {
                  return (
                    DateTime.fromISO(missingEvent.estimatedDate).month === fromDate.value.month &&
                    DateTime.fromISO(missingEvent.estimatedDate).year === fromDate.value.year
                  );
                }
              })
      ),
      events,
      eventsLoading,
      suppliers,
      suppliersLoading,
      supplierId,
      fromDate,
      activeTab: ref(0),
      displayedDocument: ref({}),
      activity: ref(),
      currentOrderDifferences,
      currentDeliveryDifferences,
      closeChatModal,
      openChatModal,
      isChatOpen,
    };
  },
  computed: {
    suppliersFilter() {
      const suppliersFiltered = [...this.suppliers];
      return [
        { name: this.$t('billing.billingManagement.allSuppliersFilter'), id: null },
        ...suppliersFiltered.sort((supplierA, supplierB) => supplierA.name.localeCompare(supplierB.name)),
      ];
    },
    subtitle() {
      const supplier =
        this.suppliers.find((s) => s.id === this.supplierId)?.name ??
        this.$t('billing.billingManagement.allSuppliersFilter');
      const date = new Date(this.month).toLocaleDateString(this.$i18n.locale, { year: 'numeric', month: 'long' });
      return `${supplier} - ${date}`;
    },
    notApproved() {
      return this.eventsLoading ? 0 : this.events.billings?.filter((billing) => billing.imbalances.length).length;
    },
    tabs() {
      return [
        {
          text: this.$t('billing.billingManagement.tab.billing'),
        },
        {
          text: this.$t('billing.billingManagement.tab.unapproved'),
          badgeValue: this.notApproved,
        },
      ];
    },
    currentSupplier() {
      return this.suppliersFilter.find((supplier) => supplier.id === this.supplierId)?.name ?? '';
    },
    commonDataLoading() {
      return this.tasksLoading || this.eventsLoading || this.suppliersLoading || this.reconciliationsLoading;
    },
    documentsLoading() {
      return this.reconciliationDocumentsLoading || this.activityDocumentsLoading;
    },
    tasksLoading() {
      return this.suppliersLoading || this.rehandleParentDocumentsLoading || this.missingEventsLoading;
    },
  },
  methods: {
    handleBillingTableRowClick({ supplierId }) {
      const rowChatChannelUrl = `reconciliation_${this.customer.id}_${supplierId}`;
      if (!this.isChatOpen(rowChatChannelUrl)) {
        this.closeChatModal();
      }
    },
    async handleCloseReconciliation(reconciliationId) {
      try {
        await this.closeReconciliation({ id: reconciliationId });
        this.$message.success(this.$t('payment.paymentTable.messages.reconciliationClosedSuccessfully'));
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('payment.paymentTable.messages.reconciliationClosingFailed'));
      }
    },
    async handleCreateTemplate(params) {
      await this.createReconciliationTemplate(params);
      await this.reconciliationTemplatesRefetch();
    },
    async handleUpdateTemplate(params) {
      await this.updateReconciliationTemplate(params);
      await this.reconciliationTemplatesRefetch();
    },
    handleChatOpen({ supplier, reconciliationId, reconciliationPeriod, reconciliationClosed, isDaily }) {
      const formattedPeriod = isDaily
        ? new Date(reconciliationPeriod).toLocaleDateString(this.$i18n.locale, options.twoDigits)
        : DateTime.fromISO(reconciliationPeriod).toLocaleString({
            month: 'short',
            year: 'numeric',
          });
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier,
        title,
        formattedPeriod,
        customer: this.customer,
        reconciliationId,
        isChannelEnabled: !reconciliationClosed,
      });
    },
    handleChatClose() {
      this.closeChatModal();
    },
    handleOpenDocument({ documentId, documentsIds }) {
      this.displayedDocument = {
        documentId,
        documentsIds,
        index: documentsIds?.indexOf(documentId),
      };
    },
    handleDocumentModalPagination(page) {
      if (page - 1 === this.displayedDocument.index) return;
      const nextIndex = page - 1;
      this.displayedDocument = {
        ...this.displayedDocument,
        documentId: this.displayedDocument.documentsIds[nextIndex],
        index: nextIndex,
      };
    },
    routeToSupplier(supplierId) {
      const route = this.$router.resolve({
        name: 'supplierActivity',
        params: { supplierId },
        query: { month: this.month },
      });
      window.open(route.href);
    },
    async validateBalanceAlignment({ reconciliationId, amount, note }) {
      try {
        await this.updateReconciliation({
          id: reconciliationId,
          patchParams: { balanceAlignment: reject(isNil, { validated: true, amount, note }) },
        });
        this.$message.success(this.$t('commons.messages.action.success'));
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('commons.messages.action.error'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.chat {
  position: absolute;
  bottom: 0;
  right: 0;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.half {
  width: 50%;
}
</style>
